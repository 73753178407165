var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deal-view-popup"},[_c('div',{staticClass:"deal-description"},[_c('div',{staticClass:"deal-description-content"},[_c('div',{staticClass:"deal-description-content-head"},[_c('p',{staticClass:"deal-description__title"},[_vm._v(_vm._s(_vm.$t("deal.info.title")))]),_c('span',{staticClass:"share",on:{"click":function($event){return _vm.copyUrl()}}},[_vm._m(0),_vm._v(" "+_vm._s(_vm.$t("global.share"))+" ")])]),_c('div',{staticClass:"deal-description__text-wrapper"},[_c('span',{staticClass:"deal-description__text",class:_vm.descriptionTextStyle},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.textCopy)}})]),(_vm.deal.description.length >= _vm.setting.maxTextLen && _vm.isHideBtn)?_c('div',{staticClass:"deal-description__btn",on:{"click":function($event){return _vm.showAllText()}}},[_vm._v(" ещё ")]):_vm._e()]),_c('ul',{staticClass:"tags"},[(_vm.deal.start_type != 0)?_c('li',{staticClass:"tag"},[_vm._v(" 🧨 с "+_vm._s(_vm.deal.start_date)+" ")]):_vm._e(),_c('li',{staticClass:"tag"},[_vm._v("⏳ "+_vm._s(_vm.frequency))]),(_vm.deal.type == 0)?_c('li',{staticClass:"tag"},[_vm._v("🗓 "+_vm._s(_vm.freq))]):_vm._e()])]),(_vm.getDealStatusInfo != 'hide')?_c('Button',{staticClass:"btn",attrs:{"button":{
        title: _vm.getDealStatusInfo,
        color: 'rgba(14, 16, 18, 0.88)',
        borderRadius: '100px',
        backgroundColor: '#1D1B20',
        color: '#fff',
        height: '40px',
        fontSize: '14px',
        paddingX: '15px',
      }},nativeOn:{"click":function($event){return _vm.handle()}}}):_vm._e(),(_vm.deal.my)?_c('Button',{staticClass:"btn",attrs:{"button":{
        title: _vm.deal.status == 0 ? 'Завершить дело' : 'Возобновить',
        color: 'rgba(14, 16, 18, 0.88)',
        borderRadius: '100px',
        backgroundColor: '#1D1B20',
        color: '#fff',
        height: '40px',
        fontSize: '14px',
        paddingX: '15px',
      }},nativeOn:{"click":function($event){return _vm.handleStatus()}}}):_vm._e(),_c('Button',{staticClass:"btn btn-complaint",attrs:{"button":{
        title: 'Пожаловаться',
        borderRadius: '100px',
        backgroundColor: 'transparent',
        height: '40px',
        fontSize: '14px',
        paddingX: '15px',
      }},nativeOn:{"click":function($event){return _vm.handleComplaint()}}})],1),_c('van-overlay',{attrs:{"show":_vm.isLoading}},[_c('div',{staticClass:"wrapper"},[_c('van-loading',{attrs:{"type":"spinner","color":"#1989fa"}})],1)]),_c('van-popup',{staticClass:"popup-task-delete",attrs:{"position":"bottom"},model:{value:(_vm.showPopoverDelete),callback:function ($$v) {_vm.showPopoverDelete=$$v},expression:"showPopoverDelete"}},[(_vm.showPopoverDelete)?_c('FinishDeal',{attrs:{"prevData":_vm.deal},on:{"cancel":function($event){_vm.showPopoverDelete = false},"updateReportlistEmit":function($event){return _vm.$emit('updateReportlistEmit')}}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-holder"},[_c('img',{attrs:{"src":require("@/assets/images/share.svg"),"alt":"..."}})])
}]

export { render, staticRenderFns }