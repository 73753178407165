<template>
  <div class="deal-view-popup">
    <div class="deal-description">
      <p class="deal-description__title">{{ $t("deal.report.title") }}</p>
      <!-- <p class="deal-description__date">
        <img src="@/assets/images/calendar.svg" alt="" />{{ today }}
      </p> -->
    </div>
    <Button
      v-if="deal.completed == 0"
      class="btn makeReport icon-absolute-left"
      @click.native="goToMakeReport()"
      :button="{
        title: $t('taskItem.addReport'),
        color: 'rgba(14, 16, 18, 0.88)',
        borderRadius: '100px',
        backgroundColor: '#1D1B20',
        color: '#fff',
        height: '40px',
        icon: 'send-2.svg',
        iconPosition: 'left',
        fontSize: '14px',
        paddingX: '15px',
      }"
    />

    <ReportItem
      v-for="(item, key) in reports"
      :key="key"
      :item="item"
      @updateReportlistEmit="getList()"
    />
    <van-overlay
      :show="isLoading"
      @click="isLoading = false"
      class="van-overlay-loading"
    >
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import ApiDeal from "@/api/deals";
import ReportItem from "@/components/Task/ReportItem";
import moment from "moment";
import { eventBus } from "@/main";
import Button from "@/components/Global/Button.vue";

export default {
  props: {
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reports: [],
      isLoading: false,
      today: moment().format("d MMMM"),
      params: {
        limit: 10,
        offset: 0,
      },
    };
  },
  computed: {},
  components: {
    ReportItem,
    Button,
  },
  methods: {
    getList() {
      ApiDeal.getReports(this.deal.id).then((responce) => {
        this.isLoading = false;
        if (responce.err[0] == 0) {
          this.reports = responce.data;
        } else {
          this.$notify(responce.err_msg[0]);
        }
      });
    },
    goToMakeReport() {
      eventBus.$emit("makeReportTab");
    },
  },
  mounted() {
    this.isLoading = true;
    moment.locale("RU");
    this.getList();

    eventBus.$on("updateReportlist", () => {
      this.getList();
    });
    eventBus.$on("stopLoader", () => {
      this.isLoading = false;
    });
  },
};
</script>
<style lang="scss" scoped>
.deal-view-popup {
  position: relative;
  overflow: hidden;
  padding-bottom: 45px;
}

.makeReport {
  position: fixed;
  bottom: 15px;
  width: calc(100% - 24px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
</style>
