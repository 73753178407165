var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delete-deal"},[_c('div',{staticClass:"delete-deal__title"},[_vm._v(" Вы уверены, что хотите завершить дело? В нем больше нельзя будет принять участие и отправлять отчеты. ")]),_c('Button',{staticClass:"btn",attrs:{"button":{
      title: 'Да, завершить',
      color: 'rgba(14, 16, 18, 0.88)',
      borderRadius: '100px',
      backgroundColor: '#FF3B30',
      color: '#fff',
      height: '56px',
      fontSize: '14px',
      paddingX: '15px',
    }},nativeOn:{"click":function($event){return _vm.confirm()}}}),_c('Button',{staticClass:"btn",attrs:{"button":{
      title: 'Не завершать',
      color: 'rgba(14, 16, 18, 0.88)',
      borderRadius: '100px',
      backgroundColor: '#1D1B20',
      color: '#fff',
      height: '56px',
      fontSize: '14px',
      paddingX: '15px',
    }},nativeOn:{"click":function($event){return _vm.cancel()}}}),_c('van-overlay',{staticClass:"van-overlay-loading",attrs:{"show":_vm.isLoading},on:{"click":function($event){_vm.isLoading = false}}},[_c('div',{staticClass:"wrapper-loading",on:{"click":function($event){$event.stopPropagation();}}},[_c('van-loading',{attrs:{"type":"spinner","color":"#1989fa"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }