<template>
  <div class="deal-view-popup">
    <div class="deal-description">
      <div class="deal-description-content">
        <p class="deal-description__title">
          {{ $t("deal.progress.makeReport") }}
        </p>
      </div>
      <div
        class="form-date col"
        :class="{
          isError: isErrorDate,
        }"
        @click="isShowCalendar = true"
      >
        <input
          readonly
          v-model="form.date"
          type="text"
          class="form-input"
          placeholder="Укажите дату"
        />
        <img src="@/assets/images/calendar.svg" alt="" srcset="" />
      </div>
      <div
        class="form-date col"
        :class="{
          isError: isErrorDesc,
        }"
      >
        <textarea
          v-model="form.text"
          :class="[
            'form-textarea',
            {
              isErrorDesc,
            },
          ]"
          placeholder="Напишите..."
        />
        <img
          src="@/assets/images/gallery-add.svg"
          @click="chooseFile()"
          alt=""
          srcset=""
        />
      </div>

      <input
        type="file"
        ref="fileInput"
        name="images"
        @change="handlePhoto"
        style="display: none"
        accept="image/*"
      />
      <template v-if="imagesList && imagesList.length">
        <van-image
          width="100"
          height="100"
          v-for="(item, key) in imagesList"
          :key="key"
          :src="item"
        />
      </template>
      <div class="delete-picture" v-if="form.photos && form.photos.length">
        <van-image width="100" height="100" :src="form.photos" />
        <Button
          class="btn"
          @click.native="deletePhoto()"
          :button="{
            borderRadius: '100px',
            backgroundColor: '#FF3B30',
            color: '#fff',
            height: '54px',
            width: '54px',
            icon: 'trash.svg',
            iconPosition: 'left',
          }"
        />
      </div>
      <div class="button-holder">
        <Button
          class="btn icon-absolute-left"
          @click.native="handle()"
          :button="{
            title: $t('deal.progress.makeReport'),
            color: 'rgba(14, 16, 18, 0.88)',
            borderRadius: '100px',
            backgroundColor: '#1D1B20',
            color: '#fff',
            height: '40px',
            icon: 'send-2.svg',
            iconPosition: 'left',
            fontSize: '14px',
            paddingX: '15px',
          }"
        />
      </div>
      <van-dialog
        v-model="isShowCalendar"
        :overlay="false"
        :show-confirm-button="false"
        class="van-dialog-calendar"
        ><CalendarReport
          :isShow="isShowCalendar"
          @onClose="isShowCalendar = false"
          @onConfirm="onConfirm"
      /></van-dialog>
    </div>

    <van-overlay
      :show="isLoading"
      @click="isLoading = false"
      class="van-overlay-loading"
    >
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Button from "@/components/Global/Button.vue";
import ApiDeal from "@/api/deals";
import { eventBus } from "@/main";
import _ from "lodash";
import CalendarReport from "@/components/Global/CalendarReport";

function deltaDate(input, days, months, years) {
  return new Date(
    input.getFullYear() + years,
    input.getMonth() + months,
    Math.min(
      input.getDate() + days,
      new Date(
        input.getFullYear() + years,
        input.getMonth() + months + 1,
        0
      ).getDate()
    )
  );
}

import moment from "moment";
import axios from "axios";
export default {
  props: {
    deal: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      isHideBtn: true,
      isShowCalendar: false,
      isErrorDate: false,
      isErrorDesc: false,
      minDate: deltaDate(new Date(2020, 2, 30), 0, -1, 0),
      maxDate: new Date(),
      form: {
        date: null,
        text: null,
        photos: null,
      },
      imagesList: [],
      images: [],
    };
  },
  computed: {},
  components: {
    Button,
    CalendarReport,
  },
  methods: {
    deletePhoto() {
      this.form.photos = [];
      this.$refs.fileInput.value = null;
    },
    handlePhoto(elem) {
      this.isLoading = true;
      axios
        .post(
          "https://media.vmeste.app/cors.php",
          { file: elem.target.files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Token": localStorage.getItem("token"),
            },
          }
        )
        .then((responce) => {
          console.log(responce);
          this.isLoading = false;
          if (responce.data.err != 0 && responce.data.err[0] != 0) {
            this.$notify({
              type: "danger",
              message: responce.data.err_msg[0],
            });
          } else {
            this.form.photos = responce.data.data;
          }
        });
    },
    chooseFile() {
      this.$refs.fileInput.click();
    },
    handle() {
      let err = 0;
      if (!this.form.date) {
        this.isErrorDate = true;
        err++;
      }

      if (!this.form.text && !this.form.photos) {
        this.isErrorDesc = true;
        err++;
      }

      if (err == 0) {
        this.isLoading = true;
        if (this.form.id) {
          ApiDeal.editReports(this.form).then((responce) => {
            if (responce.err.length && responce.err[0] != 0) {
              this.$notify({
                type: "danger",
                message: responce.err_msg[0],
              });
            } else {
              this.$emit("openignReport");
              eventBus.$emit("updateDeal");
            }
          });
        } else {
          ApiDeal.sendReports(
            Object.assign(this.form, {
              id: this.deal.id,
            })
          ).then((responce) => {
            if (responce.err.length && responce.err[0] != 0) {
              this.$notify({
                type: "danger",
                message: responce.err_msg[0],
              });
            } else {
              this.$emit("openignReport");
            }
          });
        }
      }
    },
    onConfirm(date) {
      this.isShowCalendar = false;
      this.form.date = moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    if (this.formData) {
      this.form = JSON.parse(JSON.stringify(this.formData));
    }
  },
};
</script>
<style lang="scss" scoped>
.deal-view-popup {
  position: relative;
  overflow: hidden;
}
.deal-description {
  display: block;

  .button-holder {
    margin: 0 -5px;
  }

  .button {
    position: relative;
  }
}
.deal-description-content {
  width: 100%;
  .deal-description__title {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.form-date.col {
  width: 100%;
}

.btn {
  width: 100%;
  margin-top: 20px;
}
.delete-picture {
  display: flex;
  align-items: center;
  flex-direction: column;
  button {
    // margin-left: 15px;
  }
}
</style>
