<template>
  <div class="deal-page" :style="{ backgroundColor: getDealBackground }">
    <div class="container">
      <div>
        <div class="page-header">
          <IconButton
            class="index-100"
            :van-button="{ background: 'rgba(14, 16, 18, 0.16)' }"
            :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }"
            @click="btnBack()"
          />
          <Button
            :button="{
              title: getDealStatusInfo('title'),
              color: 'rgba(14, 16, 18, 0.88)',
              borderRadius: '100px',
              backgroundColor: '#F5F5F5',
              height: '40px',
              fontSize: '14px',
              paddingX: '15px',
              icon: getDealStatusInfo('icon'),
              iconPosition: 'left',
            }"
          />
        </div>

        <div class="intro-section">
          <van-skeleton title avatar :row="3" :loading="isLoading">
            <div class="inner-holder">
              <h1 class="page-title">{{ deal.title }}</h1>
              <template v-if="deal.user && deal.user !== null">
                <div
                  class="text-holder author"
                  @click.prevent="getUser(deal.user)"
                >
                  <p>
                    Вместе с
                    <span
                      >{{ deal.user.first_name }}
                      {{ deal.user.last_name }}</span
                    >
                  </p>
                </div>
                <div
                  v-if="deal.members && deal.members.length > 0"
                  class="text-holder together-wrapper"
                  @click="$router.push(`/deal/${$route.params.id}/participant`)"
                >
                  <p>
                    {{
                      deal.acitive_users < 3
                        ? "и еще"
                        : `и еще ${deal.acitive_users}`
                    }}
                  </p>
                  <ul class="together-list">
                    <li
                      v-for="(member, index) in deal.members"
                      :key="index"
                      class="list-item"
                    >
                      <img :src="member" alt="..." />
                    </li>
                  </ul>
                </div>
              </template>
              <template v-else>
                <div
                  v-if="deal.members && deal.members.length > 0"
                  class="text-holder together-wrapper"
                  @click="$router.push(`/deal/${$route.params.id}/participant`)"
                >
                  <ul class="together-list">
                    <li class="list-item no-author">
                      <img :src="deal.members[0]" alt="..." />
                    </li>
                  </ul>
                  <p>
                    {{ `и еще ${deal.acitive_users}` }}
                  </p>
                </div>
              </template>
            </div>
            <div class="buttons-holder">
              <Button
                v-if="deal.chat"
                @click.native="goTo(deal.chat)"
                :button="{
                  title: 'Перейти в чат',
                  color: 'rgba(29, 27, 32, 0.88)',
                  borderRadius: '100px',
                  backgroundColor: 'transparent',
                  height: '40px',
                  fontSize: '16px',
                  paddingX: '17px',
                  borderColor: 'rgba(14, 16, 18, 0.16)',
                  icon: getDealStatusInfo('tg'),
                  iconPosition: 'left',
                }"
              />
              <Button
                v-if="deal.channel"
                @click.native="goTo(deal.channel)"
                :button="{
                  title: 'Перейти в канал',
                  color: 'rgba(29, 27, 32, 0.88)',
                  borderRadius: '100px',
                  backgroundColor: 'transparent',
                  height: '40px',
                  fontSize: '16px',
                  paddingX: '17px',
                  borderColor: 'rgba(14, 16, 18, 0.16)',
                  icon: getDealStatusInfo('tg'),
                  iconPosition: 'left',
                }"
              />
            </div>
          </van-skeleton>

          <div class="buttons-holder tabs-btn">
            <Button
              v-for="(item, key) in tabs"
              :key="key"
              :class="{
                activeTab: activeTab == key,
              }"
              @click.native="openPopup(item, key)"
              :button="{
                color: 'rgba(29, 27, 32, 0.88)',
                borderRadius: '100px',
                backgroundColor: 'transparent',
                height: '40px',
                fontSize: '16px',
                paddingX: '17px',
                borderColor: 'rgba(14, 16, 18, 0.16)',
                icon: item.icon,
                iconPosition: 'center',
              }"
            />
          </div>
        </div>
      </div>

      <!-- <pre>
        {{ deal }}
      </pre> -->

      <div class="deal-view-popup-container" v-if="showPopover">
        <component
          :is="dealTabs"
          :deal="deal"
          :formData="formData"
          @updateDeal="getDeal()"
          @openignReport="openignReport"
        />
      </div>
    </div>

    <ModalList title="Участники" ref="modalParticipants">
      <Participants :deal-id="$route.params.id" />
    </ModalList>
  </div>
</template>

<script>
import IconButton from "@/components/Global/IconButton.vue";
import Button from "@/components/Global/Button.vue";
import ModalList from "@/components/Global/ModalList.vue";
import ApiDeals from "@/api/deals.js";
import moment from "moment";
import Participants from "./components/Participants.vue";
// T A B S
import Info from "./components/Info.vue";
import Progress from "./components/Progress.vue";
import Report from "./components/Report.vue";
import MakeReport from "./components/MakeReport.vue";
import { eventBus } from "@/main";

import _ from "lodash";
export default {
  data() {
    return {
      tabsCopy: [
        {
          type: "info",
          icon: "info-circle.svg",
          component: "Info",
        },
        {
          type: "progress",
          icon: "calendar-circle.svg",
          component: "Progress",
        },
        {
          type: "report",
          icon: "messages-2.svg",
          component: "Report",
        },
        {
          type: "addReport",
          icon: "edit.svg",
          component: "MakeReport",
        },
      ],
      tabs: [
        {
          type: "info",
          icon: "info-circle.svg",
          component: "Info",
        },
        {
          type: "progress",
          icon: "calendar-circle.svg",
          component: "Progress",
        },
        {
          type: "report",
          icon: "messages-2.svg",
          component: "Report",
        },
        {
          type: "addReport",
          icon: "edit.svg",
          component: "MakeReport",
        },
      ],
      deal: {},
      active: 0,
      today: new Date(),
      showPopover: false,
      isLoading: false,
      dealTabs: "Info",
      activeTab: 0,
      formData: {},
      wasAdd: false,
    };
  },
  computed: {
    getDealBackground() {
      if (this.deal.status === 0) {
        return `#${this.deal.category && this.deal.category.color}`;
      } else if (this.deal.status === 1) {
        return "#B3B3B3";
      }
    },
  },
  watch: {
    "$route.params.id": {
      handler: function (user_id) {
        this.getDeal();
      },
      deep: true,
      immediate: true,
    },
    "$route.query.component": {
      handler: function (user_id) {
        this.setCorrectTab();
      },
    },
  },
  components: {
    IconButton,
    Button,
    ModalList,
    Participants,
    //Tabs
    Info,
    Progress,
    Report,
    MakeReport,
  },
  methods: {
    setCorrectTab() {
      if (this.$route.query.component) {
        this.dealTabs = this.$route.query.component;
        if (this.dealTabs == "Progress") {
          this.activeTab = 1;
        } else if (this.dealTabs == "Report") {
          this.activeTab = 2;
        } else if (this.dealTabs == "MakeReport") {
          this.activeTab = 3;
        }
      }
    },
    btnBack() {
      if (this.wasAdd || history.length === 0) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    getUser(item) {
      console.log(item);
      this.$router.push(`/?user_id=${item.id}`);
    },
    openignReport() {
      this.dealTabs = "report";
      this.activeTab = 2;
    },
    getDeal() {
      this.isLoading = true;
      ApiDeals.getDeal({
        id: this.$route.params.id,
        today: moment(this.today).format("YYYY-MM-DD"),
      })
        .then((response) => {
          this.deal = response.data;
          this.showPopover = true;

          if (response.data.joined === 0) {
            this.tabs = _.filter(this.tabs, (item) => {
              return item.type == "info" || item.type == "report";
            });
          } else {
            this.tabs = this.tabsCopy;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDealStatusInfo(value) {
      if (value === "icon") {
        return (this.deal.start_date && this.deal.start_date) > this.today
          ? "timer.svg"
          : "flash.svg";
      } else if (value === "title") {
        return (this.deal.start_date && this.deal.start_date) > this.today
          ? "Дело еще не начато"
          : "Активное дело";
      } else if (value === "tg") {
        if (this.deal.chat && this.deal.channel) {
          return null;
        } else if (this.deal.chat || this.deal.channel) {
          return "telegram.svg";
        }
      }
    },
    goTo(link = "") {
      window.location.href = link;
    },
    openPopup(item = {}, key) {
      this.showPopover = true;
      this.dealTabs = item.component;
      this.activeTab = key;
    },
  },
  mounted() {
    this.setCorrectTab();
    this.getDeal();
    eventBus.$on("editReport", (data) => {
      this.formData = data;
      this.activeTab = 3;
      this.dealTabs = "MakeReport";
    });

    eventBus.$on("makeReportTab", (data) => {
      this.activeTab = 3;
      this.dealTabs = "MakeReport";
    });

    eventBus.$on("createdTask", () => {
      this.getDeal();
    });

    eventBus.$on("updateReportlist", () => {
      this.getDeal();
    });

    eventBus.$on("wasJoined", () => {
      this.wasAdd = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.tabs-btn {
  margin-top: 16px;
}
.deal-page {
  flex: 1;

  .page-header {
    display: flex;
    justify-content: space-between;
    padding-left: 11px;
    margin-bottom: 24px;
  }

  .intro-section {
    .inner-holder {
      padding-left: 11px;
    }

    .page-title {
      margin-bottom: 16px;
      font-family: "Prata";
      font-weight: 400;
      font-size: 32px;
      line-height: 1.2;
      color: rgba(14, 16, 18, 0.88);
    }

    .text-holder {
      font-size: 16px;
      list-style: 1.5;
      color: rgba(14, 16, 18, 0.64);
      &.author {
        margin-bottom: 10px;
      }
      span {
        color: rgba(14, 16, 18, 0.88);
      }
    }

    .together-wrapper {
      display: flex;
      align-items: center;
      padding-top: 5px;
      margin-bottom: 17px;
    }

    .together-list {
      display: flex;
      margin-left: 8.5px;

      .list-item {
        width: 24px;
        height: 24px;
        &.no-author {
          margin-left: -8.5px;
          margin-right: 8.5px;
        }
        &:not(:first-child) {
          margin-left: -10px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .buttons-holder {
      display: flex;

      .button {
        width: 100%;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
