<template>
  <div class="delete-deal">
    <div class="delete-deal__title">
      {{ $t("deal.confirm_delete.title_report") }}
    </div>
    <Button
      class="btn"
      @click.native="confirm()"
      :button="{
        title: $t('deal.confirm_delete.confirm'),
        color: 'rgba(14, 16, 18, 0.88)',
        borderRadius: '100px',
        backgroundColor: '#FF3B30',
        color: '#fff',
        height: '56px',
        fontSize: '14px',
        paddingX: '15px',
      }"
    />

    <Button
      class="btn"
      @click.native="cancel()"
      :button="{
        title: $t('deal.confirm_delete.cancel'),
        color: 'rgba(14, 16, 18, 0.88)',
        borderRadius: '100px',
        backgroundColor: '#1D1B20',
        color: '#fff',
        height: '56px',
        fontSize: '14px',
        paddingX: '15px',
      }"
    />

    <van-overlay
      :show="isLoading"
      @click="isLoading = false"
      class="van-overlay-loading"
    >
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Button from "@/components/Global/Button.vue";
import ApiDeals from "@/api/deals";
import { eventBus } from "@/main";
export default {
  props: {
    prevData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    Button,
  },
  methods: {
    confirm() {
      this.isLoading = true;
      // eventBus.$emit("startLoader");
      ApiDeals.deleteReport(this.prevData.id).then(() => {
        this.$emit("cancel");
        eventBus.$emit("updateReportlist");
        this.$emit("updateReportlistEmit");
        this.isLoading = false;
        this.$notify({
          message: "Запись удалена.",
          type: "error",
        });
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {
    console.log(this.prevData.id);
  },
};
</script>

<style lang="scss" scoped>
.delete-deal {
  padding: 56px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #1e1e1c;
    margin-bottom: 40px;
  }
  ::v-deep .button {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>