<template>
  <van-skeleton title :row="3" :loading="isSelfUserLoading">
    <ul class="participants-list">
      <li
        v-for="participant in participants"
        :key="participant.id"
        class="list-item"
        @click="getUser(participant.id)"
      >
        <div class="avatar-holder">
          <img
            :src="participant.avatar"
            :alt="`${participant.first_name} ${participant.last_name}`"
          />
        </div>
        {{ participant.first_name }} {{ participant.last_name }}
      </li>
    </ul>
  </van-skeleton>
</template>

<script>
import ApiDeals from "@/api/deals.js";

export default {
  props: {
    dealId: {
      type: (Number, String),
      default: null,
    },
  },
  data() {
    return {
      participants: null,
      isSelfUserLoading: true,
    };
  },
  methods: {
    getUser(item) {
      this.$router.push(`/?user_id=${item}`);
    },
    getParticipants() {
      this.isSelfUserLoading = true;
      ApiDeals.getParticipantsDeal(this.dealId)
        .then((response) => {
          this.isSelfUserLoading = false;
          //  console.log(response.data);
          this.participants = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getParticipants();
  },
};
</script>

<style lang="scss" scoped>
.participants-list {
  padding: 0 16px;

  .list-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #0e1012;
    padding: 16px 0 13px 0;
    border-bottom: 1px solid #f2f2f2;

    .avatar-holder {
      width: 24px;
      height: 24px;
      margin-right: 17px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
</style>
