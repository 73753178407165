<template>
	<div class="report-item">
		<!-- <pre>{{ copyItem }}</pre> -->
		<div class="report-item-user">
			<div class="report-item-user-avatar">
				<img :src="item.user.avatar" :alt="copyItem.user && copyItem.user.id" />
				<div>
					<p>{{ fullname }}</p>
					<p class="for-deal">{{ $t("global.for") }} {{ item.deal.title }}</p>
				</div>
			</div>
			<div class="report-item-user-controll">
				<p class="report-item-user-controll__time">
					{{ itemDate(copyItem.date) }}
				</p>

				<el-dropdown trigger="click">
					<img src="@/assets/images/more-2.svg" />
					<template #dropdown>
						<el-dropdown-menu class="report-dropdown">
							<el-dropdown-item v-if="copyItem.my" @click.native="editReport()"> Редактировать отчет </el-dropdown-item>
							<el-dropdown-item v-if="copyItem.my" class="danger" @click.native="deleteReport()"> Удалить отчет </el-dropdown-item>
							<el-dropdown-item class="danger" @click.native="sendComplaint()" v-if="!copyItem.my"> Пожаловаться </el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
		<p class="report-item__text">{{ copyItem.text }}</p>
		<div class="report-item-img-wrap" v-if="copyItem.photos && copyItem.photos.length">
			<img :src="copyItem.photos" class="report-item__img" alt="" />
		</div>
		<div class="report-item-stat">
			<div class="report-item-stat-like">
				<img :src="require(`@/assets/images/${heartIcon}.svg`)" alt="" @click="setLike()" />
				<Transition>
					<p v-if="copyItem.likes != 0">
						{{
							$t("deal.report.liked", {
								like: copyItem.likes
							})
						}}
					</p>
				</Transition>
			</div>
			<div class="report-item-stat-comment">
				<!-- <img src="@/assets/images/comment.svg" alt="" /> -->
				<!-- <p>{{ item.likes }}</p> -->
			</div>
		</div>

		<van-popup v-model="showPopoverDelete" position="bottom" class="popup-task-delete">
			<Delete v-if="showPopoverDelete" :prevData="item" @cancel="showPopoverDelete = false" @updateReportlistEmit="$emit('updateReportlistEmit')" />
		</van-popup>
	</div>
</template>

<script>
import moment from "moment";
import ApiDeals from "@/api/deals";
import { eventBus } from "@/main";
import Delete from "@/components/Task/ReportDelete";

export default {
	props: {
		item: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			descriptionTextStyle: "text-normal",
			heartIcon: "heart",
			copyItem: {},

			filterBy: "all",
			showPopover: false,
			showPopoverDelete: false,
			actions: [
				{ text: "Все", type: "all" },
				{ text: "Активные", type: "active" },
				{ text: "Созданные", type: "created" },
				{ text: "Завершенные", type: "finished" }
			]
		};
	},
	computed: {
		fullname() {
			return `${this.copyItem.user && this.copyItem.user.first_name} ${this.copyItem.user && this.copyItem.user.last_name}`;
		}
	},
	components: {
		Delete
	},
	methods: {
		editReport() {
			console.log(1);
			eventBus.$emit("editReport", this.copyItem);
		},
		sendComplaint() {
			eventBus.$emit("startLoader");
			ApiDeals.sendComplaint(this.copyItem.id).then(() => {
				eventBus.$emit("stopLoader");
				this.$notify({
					message: "Жалоба отправлена",
					type: "error"
				});
			});
		},
		deleteReport() {
			this.showPopoverDelete = true;
		},
		onClickOutside() {
			this.showPopover = false;
		},
		setLike() {
			this.copyItem.liked = !this.copyItem.liked;

			if (this.copyItem.liked) {
				this.heartIcon = "heart-active";
				this.copyItem.likes++;

				ApiDeals.setLike(this.copyItem.id);
			} else {
				this.heartIcon = "heart";
				this.copyItem.likes--;

				ApiDeals.removeLike(this.copyItem.id);
			}
		},
		itemDate(date) {
			moment.locale("ru");
			return moment(date).format("dddd D MMMM");
		}
	},
	mounted() {
		this.copyItem = JSON.parse(JSON.stringify(this.item));

		if (this.copyItem.liked) {
			this.heartIcon = "heart-active";
		} else {
			this.heartIcon = "heart";
		}

		if (this.item.text.indexOf("\n") != -1) {
			this.descriptionTextStyle = "text-pre";
		}
	}
};
</script>

<style lang="scss" scoped>
.report-item {
	border-bottom: 1px solid rgba(29, 27, 32, 0.08);
	padding-bottom: 15px;
	margin-bottom: 15px;
	.for-deal {
		font-size: 14px;
		color: rgba(29, 27, 32, 0.56);
	}

	&-img-wrap {
		width: 100%;
	}
	&__text {
		font-family: "Inter";
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #1d1b20;
		margin-bottom: 10px;

		&.text-pre {
			white-space: break-spaces;
			word-break: break-word;
		}

		&.text-normal {
			white-space: break-spaces;
		}
	}
	&__img {
		max-height: 343px;
		margin-bottom: 10px;
		border-radius: 12px;
		max-width: 100%;
	}
	&-user {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 14px;
		&-avatar {
			display: flex;
			align-items: center;
			gap: 8px;
			img {
				width: 40px;
				height: 40px;
				border-radius: 100%;
			}
		}
		&-controll {
			display: flex;
			align-items: center;
			gap: 8px;
			flex: 1;
			justify-content: flex-end;
			text-align: end;

			&__time {
				color: rgba(29, 27, 32, 0.56);
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
			}
			img {
				cursor: pointer;
			}
		}
	}
	&-stat {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 20px;
		&-like {
			display: flex;
			align-items: center;
			gap: 10px;

			img {
				cursor: pointer;
			}
			p {
				color: rgba(29, 27, 32, 0.56);
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				margin-left: 5px;
			}
		}
	}
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
