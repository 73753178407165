<template>
	<div class="modal-list">
		<van-popup v-model="isOpen" :style="{ width: '100%', height: '100%' }">
			<div class="modal-header">
				<IconButton class="index-100" :van-button="{ background: 'rgba(14, 16, 18, 0.16)' }" :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }" @click="handleClose" />
				<h3 class="modal-title">{{ title }}</h3>
			</div>
			<slot />
		</van-popup>
	</div>
</template>

<script>
import IconButton from "@/components/Global/IconButton.vue";

export default {
	props: {
		title: {
			type: String,
			default: "title"
		}
	},
	components: {
		IconButton
	},
	data() {
		return {
			isOpen: false
		};
	},
	methods: {
		handleOpen() {
			this.isOpen = true;
		},
		handleClose() {
			this.isOpen = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.modal-list {
	.van-popup {
		padding: 16px 8px;
	}

	.modal-header {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0 13px;
		margin-bottom: 24px;

		.modal-title {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			font-family: "Prata";
			font-weight: 400;
			font-size: 18px;
			line-height: 1;
		}
	}
}
</style>
